.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: Arial, sans-serif;
    font-size: 24px;
    color: purple;
}

.loading span {
    display: inline-block;
    animation: typing 4s steps(40, end), blink 0.75s step-end infinite;
    white-space: nowrap;
    overflow: hidden;
    border-right: 4px solid;
    box-sizing: border-box;
}

@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes blink {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: #333;
    }
}
.community{
    display: flex;
    flex-direction: row;
    text-align: center;
    font-family: 'MyFont', NuevaStd-BoldExtended;
    color:purple;
    font-size: 28px;
    justify-content: center;
    margin-top: 20px;
}