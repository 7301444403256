.YouTubeContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.history {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 0px;
    gap: 10%;
    max-height: 151px;
}

.youtube-content {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.youtube-title {
    width: 100%;
    background-color:white;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;
    text-justify: initial;
}

.search{
    margin-top: 10px;
    background-color: transparent;
    border:0px;

}
.search-input{
    background-color: transparent;
    height:40px;
    border:none;
    border-radius: 0;
 
    word-break: break-all;
    /* border-bottom: 1px solid grey; */
}

  
.search-input:focus {
    color: #8898aa;
    background-color: transparent;
    outline: 0;
}

.history-search {
    background-color: transparent;
    border: 1px;
}

.history-search-input {
    background-color: white;
    height: 40px;
    width: 350px;
    color: purple;
    border-radius: 10px;
    border: none;
    box-shadow: 2px 2px 2px lightgrey;
    text-indent: 40px;
    margin-right: 5px;
}

.history-search-input::placeholder {
    color: #a1a1a1;
}

.history-search-input:focus {
    color: purple;
    background-color: white;
    outline: 0;
}
/* translateWidgetStyles.css */
/* Styling for the Google Translate dropdown */
.goog-te-combo {
    font-size: 14px;
    border: 1px solid #ccc;
    padding: 5px;
    background-color: #f9f9f9;
    color: #333;
    border-radius: 4px;
}

/* Styling for the Google Translate icon */
.goog-te-gadget-simple img {
    display: none;
}

.goog-te-gadget-simple .goog-te-gadget-icon {
    background-image: url('/src/assets/img/f_logo_RGB-Blue_58.png');
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
}

/* Styling for the Google Translate tooltip */
.goog-tooltip {
    font-size: 14px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    color: #333;
    border-radius: 4px;
    padding: 5px;
}
.goog-te-gadget-simple div.goog-te-gadget-icon:hover {
    display: none !important;
}
