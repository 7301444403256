/* Message.css */

.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.fixed-container {
  position: relative;
  height: 62dvh;
  /* Adjust height as needed */
  overflow-y: auto;
  /* Change to auto to enable scrolling */
  margin-bottom: 20px;
}

.message-container {
  padding-right: 20px;
  /* Add padding to ensure scrollbar doesn't overlay content */
}

.message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.user-message {
  justify-content: flex-end;
}

.admin-message {
  justify-content: flex-start;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
}

.message-content {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  background-color: #eceff1;
  padding: 10px;
  border-radius: 10px;
  margin-left: 10px;
}

.message-content-admin {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  background-color: #abb4e8;
  padding: 10px;
  border-radius: 10px;
  /* margin-left: 10px; */
  color: black;
}

.info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sent-time {
  font-size: 12px;
  color: black;
}

.status {
  font-size: 12px;
  color: #777;
}

.input {
  margin-bottom: 0;
}

.input:focus {
  outline: none;
  border-color: indigo;
  /* Change border color on focus */
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .message-content {
    max-width: 70%;
  }
}

@media screen and (max-width: 576px) {
  .message-content {
    max-width: 60%;
  }

  .typing {
    margin-left: 20px;
  }
}

/* typing indicator subtle */
.typing-indicator-subtle {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  background: rgba(0, 0, 0, 0.05);
  padding: 3px 16px;
  border-radius: 6px;
  font-size: 0.9rem;
  color: #666;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(8px);
}

.typing-indicator-subtle::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #64b5f6;
  border-radius: 50%;
  margin-right: 8px;
  animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.5;
  }
}

/* Optional: Add transition for smooth appearance */
.typing-indicator-subtle {
  transition: opacity 0.3s ease-in-out;
}

/* Optional: Add hover effect */
.typing-indicator-subtle:hover {
  background: rgba(0, 0, 0, 0.08);
}
