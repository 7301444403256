body {
    top: 0px !important;
}

body > .skiptranslate > iframe.skiptranslate {
    display: none !important;
    visibility: hidden !important;
}
.goog-te-gadget-simple {
    background-color: #FFF;
    /* border-left: 1px solid #D5D5D5; */
    /* border-top: 1px solid #9B9B9B; */
    /* border-bottom: 1px solid #E8E8E8; */
    /* border-right: 1px solid #D5D5D5; */
    /* font-size: 10pt; */
    /* display: inline-block; */
    padding-top: 5px;
    padding-bottom: 4px;
    cursor: pointer;
    border-radius: 10px;
}