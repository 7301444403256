.video-div {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #161515;
  .stream-container {
    height: 90vh;
    display: grid;
    gap: 10px;
    padding: 10px;
    justify-items: center;
    align-items: center;
    background-color: transparent;
    &.ch-1 {
      grid-template-columns: repeat(1, 1fr);
	  &.D-St {
        max-width: 500px;
        max-height: 500px;
        background-color: transparent;
      }
    }
    &.ch-2 {
      grid-template-columns: repeat(2, 1fr);
	  &.D-St {
        max-width: 500px;
        max-height: 500px;
      }
    }
    &.ch-3 {
      grid-template-columns: repeat(3, 1fr);
    }
    &.ch-4 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    &.ch-5 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    &.ch-6 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    &.ch-7 {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    &.ch-8 {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

	.peerAvater-div {
		display: flex;
		flex-direction: column;
		grid-gap: 10px;
		gap: 10px;
		justify-content: center;
		align-items: center;
		height: 100%;
	  }
    #peerAvater-frame {
      width: 60px;
      height: 60px;
    }
	.btn-peer{
		display:flex;
		gap: 10px;
	}
	#local-avatar{
		.btn-peer{
			display: none;
		}
	}
  }

  
 

  #local-stream,
  #share-stream,
  .peer-stream{
	width: 100%;
	height: 100%;
  }
  .video-control {
    height: 10vh;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items:center;
    gap: 10px;
    .local-control {
      background: transparent;
      border-radius: 30px;
      overflow: hidden;
      border: none;
      outline: 2px solid #efeff4;
    }
	.user-list{
		.badge-primary{
			background-color: transparent;
		}
	}
  }
  .local-video-div,
  .peer-video-div {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    align-items: center;
    display: grid;
    // justify-content: center;
    // align-content: center;
overflow: hidden;
object-fit: contain;
    background: #48498945;
  }
  .btn-action {
    $height: 45px;
    height: $height;
    width: $height;
    margin: 3px 6px;
	padding: 3px;
    text-align: center;
    border-radius: 50%;
    border: solid 1px transparent;
    cursor: pointer;
    transition-duration: 0.25s;
    background-color: white;
    font-size: 1.2rem;
    &:hover {
      background-color: rgba(#4f2bab, 0.2);
    }
  }
}
