//@import "~bootstrap/scss/bootstrap";

@import "variables";
@import "shared/base";

.main-window  {
  color: $main-color;
	font-size: $main-font-size;
	letter-spacing: 0.5px;
}

.btn-action {
  outline: none;
  border: none;
}

.no-peer{
  width: 100%;
  height: 100%;
  background-color: #000;
}


@import "main-window";
@import "call-window";
@import "call-modal";
@import "peerAvatar";
