.main-window {
	font-size: 1.5em;
	height: 100vh;
	background-color: #deeaf6;
	// padding: 20px;

	.logo-container {
		background: url('../../img/channels4_banner.jpg');
		/* Added background image */
		background-size: contain;
		
		height: 250px;
		background-repeat: no-repeat;
		width: 100%;


	}

	.btn-action {
		$height: 45px;
		height: $height;
		width: $height;
		margin: 16px 20px 0px 0px;
		text-align: center;
		border-radius: 50%;
		border: solid 2px #4f2bab;
		cursor: pointer;
		transition-duration: 0.25s;
		background-color: transparent;
font-size: 1.4rem;
		&:hover {
			background-color: rgba(#4f2bab, 0.2);
		}
	}

	.main-call-container {
		display: flex;
		flex-direction: row;
		padding: 10px;
		justify-content: space-between;
	}

	.call-container-mode {
		border-radius: 15px;
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		box-shadow: none;

		/* Removed box-shadow */
		.local-video-frame-init {
			height: 300px;
			width: 500px;
			background-color: black;
			border-radius: 10px;
		}

		.peerAvater-div {
			height: 300px;
			width: 500px;
			background: #5b5b8c;
			border-radius: 10px;


			#peerAvater-frame {
				height: 80px;
				width: 80px;
			}
		}
	}

	.call-container-join-cnt {
		position: relative;
		background-color: transparent;
		padding: 30px;
		margin: 20px auto;
		width: 50%;
		max-height: 300px;
		/* Reduced the height of the video frame */
		border-radius: 15px;
		text-align: center;

		.call-join-box {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
		}
	}

	.call-theme-poem {
		font-size: 1.1rem;
		font-weight: 500;
		color: #5f6368;
		/* Grey text */
		margin-bottom: 20px;
		border-left: 1px solid black;
		border-radius: 10px;
		border-right: 1px solid black;
	}

	.call-message {
		h1 {
			font-size: 1.6rem;
			font-weight: 600;
			color: #202124;
			margin-bottom: 15px;
		}

		h2 {
			font-size: 1.2rem;
			font-weight: 400;
			color: #5f6368;
		}

		.peerAvater-div {
			flex-direction: row-reverse;

			.peerAvater-frame {
				height: 30px;
				width: 30px;
			}

			.peerAvater-name {
				font-size: 1rem;
			}
		}
	}



	.call-actions {
		display: flex;
		justify-content: space-between;
		margin-top: 18px;
		flex-direction: column;
		gap: 10px;
	}

	/* Button styles */
	.btn-cancel,
	.btn-join {
		font-size: 1rem;
		padding: 10px 25px;
		border-radius: 25px;
		/* Round buttons */
		background-color: #ffffff;
		border: 2px solid transparent;
		transition: background-color 0.3s ease;
	}

	.btn-join {
		background-color: green;
		/* Google Meet's blue color */
		color: white;

		&:hover {
			background-color: rgb(green, 0.8);
		}
	}



	.btn-cancel {
		color: #ea4335;
		/* Red cancel color */
		background-color: #ffffff;

		&:hover {
			background-color: rgba(234, 67, 53, 0.1);
		}
	}




}

// mobie screen
@media screen and (max-width: 768px) {
	.main-window {
		padding: 5px;

		.logo-container {
			// background-size: contain;
			max-height: 60px;
			background-position: center;
		}

		.main-call-container {
			flex-direction: column;
			min-height: 80%;
			justify-content: center;
		}

		.call-container-mode {

			// flex-direction: column;

			.local-video-frame-init {
				height: 100%;
				width: 100%;
				border-radius: 10px;
			}

			.peerAvater-div {
				width: 100%;
				height: 250px;

				#peerAvater-frame {
					height: 60px;
					width: 60px;
				}
			}
		}

		.btn-action {
			height: 40px;
			width: 40px;
			font-size: 1rem;
		}

		.call-container-join-cnt {

			padding: 0;
			margin: 0;
			width: 100%;


			.call-join-box {
				flex-direction: column-reverse;
			}
		}

		.call-theme-poem {
			font-size: 0.8rem;

		}

		.call-message {
			h1 {
				font-size: 1rem;

			}

			h2 {
				font-size: 1rem;

			}

			.peerAvater-div {
				flex-direction: row-reverse;

				.peerAvater-name {
					font-size: 0.8rem;
				}
			}
		}

		.btn-cancel,
		.btn-join {
			font-size: 0.8rem;
			padding: 5px 15px;

		}
	}


}

@media (min-width: 768px) and (max-width: 1024px) {
	.main-window {
		.logo-container {
			max-height: 130px;
			background-position: center;
		}

		.main-call-container {
			flex-direction: column;
			padding: 0;
		}

		.call-container-mode {
			padding: 10px;
		}

		.call-container-join-cnt {
			width: 100%;
			padding: 1px 15px;
			margin: auto;

			.call-join-box {
				flex-direction: column-reverse;
			}
		}

		.btn-action {
			height: 50px;
			width: 50px;
		}

		.call-actions {
			margin: 10px;
		}

		.btn-cancel,
		.btn-join {
			font-size: 1rem;
			padding: 5px 15px;


		}

		.call-theme-poem {
			margin-bottom: 5px;
		}
		.call-message h1 {
			font-size: 1.4rem;
		margin-bottom: 0px;
		}
	}

}
@media (min-width: 1024px) and (max-width: 1200px) {
	.main-window .call-container-join-cnt .call-join-box {
		flex-direction: column-reverse;
	}
}