.footer {
    background: #4f2bab;
    color: white;
    padding:  0;
    text-align: center;
    width: 100%;
  }
  
  .footer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    justify-content: space-between;
    /* max-width: 1200px; */
    margin: 0 auto;
    padding: 0 1rem;
  }
  /* 
  .footer-logo-container {
    margin-bottom: 1rem;
  } */
  
  .footer-logo {
    max-width: 150px;
    height: auto;
    
  }
  
  .footer-social-media {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 0;
    text-align: center;
    align-items: center;
  }
  
  .footer-icon {
    color: white;
    font-size: 1.5rem;
    transition: transform 0.3s;
    padding: 0 7px;
  }
  .footer-social-media>:nth-child(1){
    padding: 0 10px; 
  }
  .footer-social-media>:nth-child(1):hover{
    font-size: 1.3rem; 
  }
  .footer-icon:hover {
    color: black;
    border-radius: 50px;
    border: 1px solid white;
    transform: scale(1.1);
    
   
  }
  
  
  .footer-rights {
    font-size:1rem !important;
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
    .footer-logo {
      max-width: 125px !important;
     
    }
  
    .footer-logo-container, .footer-rights {
      margin-bottom: 0;
    }
    .footer-icon{
      font-size: 1rem;
    }
    .footer-social-media {
      gap: 0.5rem;
    }
    
  .footer-rights {
    font-size:0.85rem !important;
  }
  }