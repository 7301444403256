.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .dialog-overlay.minimized {
    width: 200px;
    height: 200px;
    top: auto;
    left: auto;
    bottom: 50px;
    right: 20px;
    background-color: transparent;
  }
  
  .dialog-content-videoframe {
    width: 100vw;
    height: 100vh;
    background-color: white;
    position: relative;
    /* padding: 20px; */
    box-sizing: border-box;
  }
  
  .dialog-overlay.minimized .dialog-content-videoframe {
    width: 200px;
    height: 200px;
    background-color: lightgray;
    border-radius: 10px;
  }
  
  /* .dialog-controls {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .control-btn {
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .control-btn:hover {
    color: blue;
  } */