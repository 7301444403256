.peerAvater-div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.peerAvater-whole-body{
    height: 100%;
}

.peerAvater-name {
    font-size: 14px;
    color: black;
}

.sound-wave-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

#peerAvater-frame {
    z-index: 10; 
}


.sound-wave {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 0.3px solid #ffffff21;
    animation: waveAnimation 5s infinite ease-in-out;
    opacity: 0;
}


.sound-wave:nth-child(1) {
    animation-delay: 1s;
}


.sound-wave:nth-child(2) {
    animation-delay: 2.5s;
}


.sound-wave:nth-child(3) {
    animation-delay: 3.5s;
}


@keyframes waveAnimation {
    0% {
        transform: scale(0.8);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.5;
    }
    100% {
        transform: scale(2.2);
        opacity: 0;
    }
}

.btn-peer{
    display: none;
    align-items: center;
    justify-content: end;
    /* margin: 10px 15px; */
    position: relative;
    right: 15px;
    top: 15px;
    color: white;
}

