/* RatingComment.css */

.rating-comment {
    display: flex;
    width:80%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.stars {
    cursor: pointer;
    margin-bottom: 20px;
}

.active {
    color: #FFD642;
}

textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    margin-bottom: 10px;
}

button[type="submit"] {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
}

button[type="submit"]:hover {
    background-color: #45a049;
}